import { Box, CardMedia, Stack, Typography } from '@mui/material';
import React from 'react';

import IMG1 from '../../../assets/Landing/blog1.png';
import { Link } from 'react-router-dom';

const MainBanner = () => {
  return (
    <Stack
      spacing={0}
      direction={'column'}
      sx={{ alignItems: 'center', width: '100%', backgroundColor: '#1C486A' }}
    >
      {/* Section 1: Logo and Introduction Text */}
      <Stack
        spacing={5}
        direction={{ xs: 'column', sm: 'row' }} // Stack column on small screens, row on larger screens
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          maxWidth: '1200px',
          mx: 'auto',
          paddingX: { xs: '16px', sm: '24px', md: '32px' }, // More padding on larger screens
          py: { xs: '16px', sm: '24px', md: '48px' }, // Adjust padding for better spacing
        }}
      >
        <CardMedia
          component="img"
          src={IMG1}
          alt="Logo"
          sx={{
            width: { xs: '90%', sm: '60%', md: '40%' }, // 90% width on mobile, smaller for larger screens
            height: 'auto', // Let the height adjust automatically to maintain aspect ratio
            borderRadius: '20px',
          }}
        />
        
        <Stack direction={'column'} spacing={2}>
          <Typography
            variant="h3"
            sx={{
              color: '#f1f1f1',
              fontFamily: 'sans-serif',
              fontSize: { xs: '24px', sm: '32px', md: '40px' }, // Adjust heading size for screens
              textAlign: { xs: 'center', sm: 'left' }, // Center on small screens, left align on larger screens
            }}
          >
            What Are The Changes In Canada For Tax Season 2023?
          </Typography>

          <Typography
            variant="subtitle1"
            color="#f1f1f1"
            sx={{
              fontSize: { xs: '14px', sm: '16px', md: '18px' }, // Adjust font size for responsiveness
              textAlign: { xs: 'center', sm: 'justify' }, // Center on mobile, justify text on larger screens
              lineHeight: 1.5, // Better readability with line height
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non. Duis dapibus erat neque. Aliquam erat volutpat. Quisque condimentum ligula eget sem pellentesque blandit.
          </Typography>
        </Stack>
      </Stack>
      
      {/* Section 2: CardMedia Block */}
      <Stack
        spacing={5}
        justifyContent={'center'}
        sx={{
          backgroundColor: '#f1f1f1', // Background color for the card section
          padding: '32px', // Padding around the card block
        }}
      >
        <Stack direction={'row'} justifyContent="center" spacing={4}>
          <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>

          <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>

          <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent="center" spacing={4}>
        <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>

          <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>

          <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent="center" spacing={4}>
        <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>

          <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>

          <Stack direction="column" alignItems="center">
            <CardMedia
              component="img"
              src={IMG1}
              alt="Logo"
              sx={{
                width: { xs: '60%', sm: '50%', md: '80%' }, // Larger size for the images
                height: 'auto',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }} textAlign={'left'}>
            Tax Cuts to help Australians with cost of living?
            </Typography>
            <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla pulvinar ligula. Fusce cursus tincidunt est, at fermentum lacus maximus vel. Donec tincidunt eros sem, at pharetra neque elementum non........
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MainBanner;

import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Typography, Grid, Button, useMediaQuery, useTheme, Fade,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('340','sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', '740'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('340'));

  // State to control fade-in effect
  const [fadeIn, setFadeIn] = useState(false);

  // Create a reference for the banner
  const bannerRef = useRef(null);

  // Set up an intersection observer to trigger fade-in when the component enters the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setFadeIn(true);
            observer.disconnect(); // Stop observing once the fade-in is triggered
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the banner is visible
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Fade in={fadeIn} timeout={1000}>
      <Box
        ref={bannerRef}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={isMobile ? '500px' : isExtraSmall?'600px': '500px'}
        padding={isMobile ? 2 : 4}
        sx={{ backgroundColor: '#ffffff' }}
      >
        <Typography
          variant={'h3'}
          sx={{
            color: '#001f3f',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: isMobile ? 0 : isTablet?0:2,
            textTransform: 'uppercase',
            fontSize: isMobile ? '35px' : isExtraSmall?'27px':'50px',
          }}
        >
          New{' '}
          <span style={{ WebkitTextStroke: '1px #001f3f', color: 'transparent' }}>
            Patterns
          </span>
        </Typography>

        <Typography
          variant={'h3'}
          sx={{
            color: '#001f3f',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 2,
            textTransform: 'uppercase',
            marginTop: isExtraSmall ? -2 : 0,
            fontSize: isMobile ? '35px' : isExtraSmall?'28px':'50px',
          }}
        >
          <span style={{ WebkitTextStroke: '1px #001f3f', color: 'transparent' }}>
            Endless
          </span>{' '}
          Opportunities
        </Typography>

        <Typography
          variant={isMobile ? 'body2' : 'body1'}
          sx={{
            color: '#001f3f',
            textAlign: 'center',
            marginBottom: 4,
            maxWidth: isMobile ? '90%' : '500px',
          }}
        >
          A team of professionals dedicated to excellence, we offer comprehensive
          accounting services tailored to meet the unique needs of your business.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra
          venenatis auctor. Donec ornare nisl quis laoreet gravida.
        </Typography>

        {/* Button Group */}
        <Grid container justifyContent="center" spacing={isMobile ? 1 : 2} sx={{ marginBottom: 4 }}>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                border: 'solid #003366 2px',
                color: '#003366',
                borderRadius: '15px',
                padding: isMobile ? '8px 16px' : '8px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#f2f2f2' },
              }}
              onClick={() => handleNavigate('/Australia')}
            >
              Australia
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                border: 'solid #003366 2px',
                color: '#003366',
                borderRadius: '15px',
                padding: isMobile ? '8px 16px' : '8px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#f2f2f2' },
              }}
              onClick={() => handleNavigate('/UK')}
            >
              UK
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                border: 'solid #003366 2px',
                color: '#003366',
                borderRadius: '15px',
                padding: isMobile ? '8px 16px' : '8px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#f2f2f2' },
              }}
              onClick={() => handleNavigate('/US')}
            >
              US
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                border: 'solid #003366 2px',
                color: '#003366',
                borderRadius: '15px',
                padding: isMobile ? '8px 16px' : '8px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#f2f2f2' },
              }}
              onClick={() => handleNavigate('/Canada')}
            >
              Canada
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                border: 'solid #003366 2px',
                color: '#003366',
                borderRadius: '15px',
                padding: isMobile ? '8px 16px' : '8px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#f2f2f2' },
              }}
              onClick={() => handleNavigate('/MiddleEast')}
            >
              Middle East
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default LandingPage;

import React from 'react';
import { Card, Typography, Stack, useMediaQuery, useTheme } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import IMG1 from '../../../assets/Landing/tech.png';
import IMG2 from '../../../assets/Landing/cloths.png';
import IMG3 from '../../../assets/Landing/education.png';
import IMG4 from '../../../assets/Landing/food.png';
import IMG5 from '../../../assets/Landing/bew.png';
import IMG6 from '../../../assets/Landing/health.png';

export default function IndustriesCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', '740'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('340'));

  return (
    <Card
      sx={{
        height: isMobile ? 'auto' : '650px',
        backgroundColor: '#1C486A',
        padding: isMobile ? 2 : 4,
      }}
    >
      <Stack
                justifyContent="center"
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 2 : 4}
        sx={{ height: '100%', width: '100%', justifyContent: 'center' }}
      >
        {/* Left Section: Two vertical lines of cards */}
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 2 : 6}
          sx={{ width: isMobile ? '100%' : '40%', justifyContent: 'center'           }}
        >
          {/* First Vertical Line of Cards */}
          <Stack spacing={isMobile ? 2 : 4} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            {[{ img: IMG1, title: 'Technology' }, { img: IMG2, title: 'Clothing' }, { img: IMG3, title: 'Education' }].map((item, index) => (
              <Card
                key={index}
                sx={{
                  width: isMobile ? '120px' : '150px',
                  height: isMobile ? '120px' : '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#1C486A',
                  border: 'solid #ffffff',
                  borderRadius: '20px',
                }}
              >
                <Stack direction="column" justifyContent="center" alignItems="center">
                  <CardMedia component="img" src={item.img} alt={item.title} />
                  <Typography sx={{ color: '#fff', marginTop: 1 }}>{item.title}</Typography>
                </Stack>
              </Card>
            ))}
          </Stack>

          {/* Second Vertical Line of Cards */}
          <Stack spacing={isMobile ? 2 : 4} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            {[{ img: IMG4, title: 'Food' }, { img: IMG5, title: 'Beverage' }, { img: IMG6, title: 'Healthcare' }].map((item, index) => (
              <Card
                key={index}
                sx={{
                  width: isMobile ? '120px' : '150px',
                  height: isMobile ? '120px' : '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#1C486A',
                  border: 'solid #ffffff',
                  borderRadius: '20px',
                }}
              >
                <Stack direction="column" justifyContent="center" alignItems="center">
                  <CardMedia component="img" src={item.img} alt={item.title} />
                  <Typography sx={{ color: '#fff', marginTop: 1 }}>{item.title}</Typography>
                </Stack>
              </Card>
            ))}
          </Stack>
        </Stack>

        {/* Center Section: Vertical "INDUSTRIES" Text */}
        {!isMobile && (
          <Stack justifyContent="center" alignItems="center" sx={{ margin: '' }}>
            <Typography
              variant="h3"
              sx={{
                color: 'transparent',
                fontWeight: 'bold',
                writingMode: 'vertical-rl',
                textOrientation: 'upright',
                transform: 'rotate(360deg)',
                WebkitTextStroke: '1px #fff',
              }}
            >
              INDUSTRIES
            </Typography>
          </Stack>
        )}

        {/* Right Section */}
        <Stack
          justifyContent="center"
          sx={{
            width: isMobile ? '100%' : '50%',
            paddingLeft: isMobile ? 0 : 0,
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <Typography variant={isMobile ? 'h4' : 'h2'} sx={{ color: '#fff', fontWeight: 'bold',  fontSize:'50px',marginBottom: 2 }}>
            WE SERVE
          </Typography>
          <Typography sx={{ color: '#fff', lineHeight: 1.8 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra venenatis auctor. Donec ornare nisl
            quis laoreet gravida. Donec sollicitudin massa at orci lacinia consequat.
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CardMedia, Grid, Button, Box, TextField, useMediaQuery, useTheme } from '@mui/material';

import IMG1 from '../../../assets/Landing/contactus.png';

export default function IndustriesCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery('(max-width:845px)'); // Detect screen sizes below 845px

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: '#1C486A',
        position: 'relative',
        padding: isMobile ? 1 : 2, // Adjust padding for small screens
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: isMedium ? '30px' : '50px', // Adjust padding top for medium screens
        paddingBottom: isMedium ? '30px' : '50px',
      }}
    >
      <Typography
        variant={isMedium ? 'h4' : 'h3'} // Adjust typography variant for medium screens
        sx={{
          color: '#f1f1f1',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 2,
          textTransform: 'uppercase',
          mb:4
        }}
      >
        Book A {' '} 
        <span style={{ WebkitTextStroke: '1px #f1f1f1', color: 'transparent' }}>
        Consultation
        </span> With Us!
      </Typography>

      <Grid
        container
        spacing={isMedium ? 4 : 8} // Adjust spacing for smaller screens
        justifyContent="center"
        alignItems="center"
      >
        {/* Image section */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: {xs:'250px',sm:'250px',md:'300px',lg:'400px'}, // Adjust height for medium screens
                width: {xs:'250px',sm:'250px',md:'300px',lg:'400px'}, 
                marginLeft:{xs:'0px',sm:'0px',md:'70px',lg:'0px'}// Adjust width for medium screens
              }}
              component="img"
              src={IMG1}
              alt="Industry 1"
            />
          </Box>
        </Grid>

        {/* Contact Form */}
        <Grid item xs={12} sm={6} md={8} lg={6}>
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px', // Limit form width for better responsiveness
              background: '#fff',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '20px',
              padding: isMobile ? 2 : 3, // Adjust padding for smaller screens
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              margin: '0 auto', // Center the form horizontally
            }}
          >
            {/* Form inside the box */}
            <Box
              component="form"
              sx={{
                width: '100%',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Input fields */}
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                required
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                required
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                sx={{ marginBottom: 2 }}
              />

              {/* Submit button */}
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  borderColor: '#1C486A',
                  color: '#1C486A',
                  marginTop: 2,
                  '&:hover': {
                    backgroundColor: '#1C486A',
                    color: '#fff',
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CardMedia, Grid, Button, Box, useMediaQuery, useTheme, } from '@mui/material';
import { Link } from 'react-router-dom';

// Dummy imports for icons (replace with your own images or icons)
import IMG1 from '../../../assets/Landing/5.png';
import IMG2 from '../../../assets/Landing/4.png';
import IMG3 from '../../../assets/Landing/6.png';

export default function IndustriesCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('340','sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', '740'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('340'));

  return (
    <Box
      sx={{
        height: 'auto',
        backgroundColor: '#1C486A',
        position: 'relative',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '50px',
        paddingBottom:'50px'
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: '#f1f1f1',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: { xs: 3, sm: 5, md: 7, lg: 7 },
          textTransform: 'uppercase',
          fontSize: isMobile ? '35px' : isExtraSmall?'27px':'50px',
        }}
      >
             Stay up to date <br/>
             With our{' '}
        <span style={{ WebkitTextStroke: '1px #f1f1f1', color: 'transparent' }}>
          Latest News
        </span>
      </Typography>

      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        {/* First Vertical Line of Images */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '300px' },
                width: { xs: '200px', sm: '250px', md: '100%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG1}
              alt="Industry 1"
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '300px' },
                width: { xs: '200px', sm: '250px', md: '100%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG2}
              alt="Industry 2"
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '300px' },
                width: { xs: '200px', sm: '250px', md: '100%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG3}
              alt="Industry 3"
            />
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
}

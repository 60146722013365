import React from 'react';
import Slider from 'react-slick';
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Importing the images (make sure to have correct paths)
import IMG1 from '../../../assets/Landing/img1.png';
import IMG2 from '../../../assets/Landing/img2.png';
import IMG3 from '../../../assets/Landing/img3.png';
import IMG4 from '../../../assets/Landing/img4.png';
import IMG5 from '../../../assets/Landing/img5.png';
import IMG6 from '../../../assets/Landing/img6.png';
import IMG7 from '../../../assets/Landing/img1.png';
import IMG8 from '../../../assets/Landing/img2.png';

const ResponsiveSlider = () => {

  // Slider settings for desktop and mobile
  const settings = {
    dots: true, // Show pagination dots
    infinite: true, // Infinite scrolling
    speed: 500,
    slidesToShow: 8, // Desktop: Show 8 slides
    slidesToScroll: 1,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 5000, // Set auto sliding interval to 5 seconds (5000 ms)
    responsive: [
      {
        breakpoint: 1024, // Tablet and lower
        settings: {
          slidesToShow: 6,
        }
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 3, // Show 3 slides on mobile
        }
      }
    ]
  };

  // Sample data for cards (using actual image imports)
  const data = [
    { title: 'Slide 1', image: IMG1 },
    { title: 'Slide 2', image: IMG2 },
    { title: 'Slide 3', image: IMG3 },
    { title: 'Slide 4', image: IMG4 },
    { title: 'Slide 5', image: IMG5 },
    { title: 'Slide 6', image: IMG6 },
    { title: 'Slide 7', image: IMG7 },
    { title: 'Slide 8', image: IMG8 },
  ];

  return (
    <Box sx={{ width: '100%', maxWidth: '1200px', mx: 'auto', py: 4 }}>
      <Slider {...settings}>
        {data.map((item, index) => (
          <Box key={index} sx={{ px: 1 }}>
            <Card sx={{ minWidth: 100 }}>
              <CardMedia
                component="img"
                image={item.image}
                alt={item.title}
                sx={{ height: { xs: 100, sm: 150 }, objectFit: 'cover' }} // Make image fit nicely
              />
              <CardContent>
                <Typography variant="body2" textAlign="center">
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ResponsiveSlider;

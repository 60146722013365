import React from 'react';
import { Card, Box, useMediaQuery, useTheme } from '@mui/material';
import { IconMouse } from '@tabler/icons-react';

export default function ImgMediaCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleScroll = () => {
    window.scrollBy({
      top: 500,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Card
      sx={{
        height: isMobile ? '500px' : isTablet ? '500px' : '600px',
        backgroundColor: '#000000',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: isMobile ? '420px' : isTablet ? '400px' : '500px',
          left: '50%',
          marginLeft: '-20px',
          transform: 'translateX(-50%)',
          cursor: 'pointer',
          animation: 'bounce 2s infinite',
          '@keyframes bounce': {
            '0%, 100%': {
              transform: 'translateY(0)',
            },
            '50%': {
              transform: 'translateY(-10px)',
            },
          },
        }}
        onClick={handleScroll}
      >
        <IconMouse size={isMobile ? 30 : isTablet ? 35 : 40} color="#ffffff" />
      </Box>
    </Card>
  );
}



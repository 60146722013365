import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Router } from 'react-router-dom';

// nav_bar
import NavBar from '../../Nav_Bar/index';
import MainBanner from './MainBanner';

// Routes
import Routes from '../../../routes/index';

function App() {
  return (
      <Box>
        <Stack direction="column" spacing={-6}>
          <NavBar />
          <MainBanner/>
        </Stack>
      </Box>
  );
}

export default App;

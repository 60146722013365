import { Stack, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useMediaQuery,useTheme } from '@mui/material';
import Banner1 from './1stBanner';
import Banner2 from './2ndBanner';
import Banner3 from './3rdBanner';
import Banner3Responsive from './3rdBannerResponsive';
import Banner4 from './4thBanner';
import Banner4Responsive from './4thBannerResponsive';
import Banner5 from './5thBanner';
import Banner6 from './6thBanner';
import Banner7 from './7thBanner';
import Banner7Responsive from './7thBannerResponsive';
import Banner8 from './8thBanner';
import Banner9 from './9thBanner';
import Banner9Responsive from './9thBannerResponsive';
import FooterFull from './Footer';
import FooterFul2 from './Footer2';

function App() {
  // Use Material-UI's useMediaQuery hook to determine screen size
  //const isMobile = useMediaQuery('(max-width:200px)'); // true if screen width is 600px or less
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('845'));

  return (
    <Box sx={{backgroundColor:'black'}}>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <Banner1 />
        </Grid>
        <Grid item xs={12}>
          <Banner2 />
        </Grid>
        <Grid item xs={12}>
          {/* Conditional rendering based on screen size */}
          {isMobile ? <Banner3Responsive /> : <Banner3 />}
        </Grid>
        <Grid item xs={12}>
          {/* Conditional rendering based on screen size */}
           <Banner4 />
        </Grid>
        <Grid item xs={12}>
          {/* Conditional rendering based on screen size */}
           <Banner5 />
        </Grid>
        <Grid item xs={12}>
          {/* Conditional rendering based on screen size */}
           <Banner6 />
        </Grid>
        <Grid item xs={12}>
          {/* Conditional rendering based on screen size */}
          {isMobile ? <Banner7Responsive /> : <Banner7 />}
        </Grid>
        <Grid item xs={12}>
          {/* Conditional rendering based on screen size */}
          {!isMobile && <Banner8 />}
        </Grid>
        
        <Grid item xs={12}>
          {/* Conditional rendering based on screen size */}
           <Banner9 />
        </Grid>
        <Grid item xs={12} >
          {/* Conditional rendering based on screen size */}
           <FooterFull />
        </Grid>
        <Grid item xs={12} sx={{md:2}} >
          {/* Conditional rendering based on screen size */}
           <FooterFul2 />
        </Grid>
        
      </Grid>
    </Box>
  );
}

export default App;

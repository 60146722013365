import React from 'react';
import { Grid, Typography, Button, Stack, Box, Divider, useMediaQuery, useTheme } from '@mui/material';

// Import social icons
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Import your logo
import Logo from '../../../assets/Logo/Logo.png';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={isMobile ? 2 : 4} sx={{ height:'100%',backgroundColor: '#000', color: '#fff', padding: isMobile ? 2 : 4 }}>
      
      {/* Left Section: Logo, Contact, and Social Icons */}
      <Grid item xs={12} md={3}>
        <Stack direction="column" spacing={2} alignItems="center">
          <Box
            component="img"
            src={Logo}
            alt="Kaleido International"
            sx={{ width: isMobile ? 80 : 100, height: '100%' }}
          />
          <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
            KALEIDO INTERNATIONAL
          </Typography>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#1C94FE',
              color: '#fff',
              borderRadius: '30px',
              padding: isMobile ? '8px 16px' : '10px 20px',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: '#0072E5' },
            }}
          >
            Contact
          </Button>

          {/* Social Icons */}
          <Stack direction="row" spacing={isMobile ? 1 : 2} justifyContent="center">
            <EmailIcon />
            <FacebookIcon />
            <InstagramIcon />
            <WhatsAppIcon />
          </Stack>
        </Stack>
      </Grid>

      {/* Center Section: Navigation Links */}
      <Grid item xs={12} md={6} container spacing={isMobile ? 2 : 4}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Home
          </Typography>
          <Divider sx={{ borderColor: '#fff', marginBottom: 1 }} />
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            About Us
          </Typography>
          <Divider sx={{ borderColor: '#fff', marginBottom: 1 }} />
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Blogs
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Our Services
          </Typography>
          <Divider sx={{ borderColor: '#fff', marginBottom: 1 }} />
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Budgeting
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Bookkeeping
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Accounting
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Tax Consultation
          </Typography>
        </Grid>
      </Grid>

      {/* Right Section: Policy Links */}
      <Grid item xs={12} md={3}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Policy
        </Typography>
        <Divider sx={{ borderColor: '#fff', marginBottom: 1 }} />
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          Privacy Terms
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;

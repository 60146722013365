import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Router } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// nav_bar
import NavBar from '../../Nav_Bar/index';
import MainBanner from './MainBanner';
import Footer1 from './Footer';
import Footer2 from './Footer2';
import ContactUs from './ContactUs';
import ContactUsResponsive from './ConatcUsResponsive';
// Routes
import Routes from '../../../routes/index';

function App() {
  
  const theme = createTheme(); // Create a default MUI theme
  // Use Material-UI's useMediaQuery hook to determine screen size
  const isMobile = useMediaQuery('(max-width:600px)'); // true if screen width is 600px or less

  return (
      <Box>
        <Stack direction="column" spacing={-6}>
        <ThemeProvider theme={theme}>
        <CssBaseline />
          <NavBar />
          <MainBanner />
        {/* Conditional rendering based on screen size */}
        {isMobile ? <ContactUsResponsive /> : <ContactUs />}
          <br/>
          <br/>
          <Footer1 />
          <br/>
          <br/>
          <Footer2/>
        </ThemeProvider>
        </Stack>
      </Box>
  );
}

export default App;

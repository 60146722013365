import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from '../View/Components/Landing/index';
import GlobalManinPage from '../View/Components/Home/index';
import GlobalServices from '../View/Components/Servises/index';
import GlobalAbout from '../View/Components/About/index';
import GlobalBlogs from '../View/Components/Blogs/index';
import GlobalConatctUs from '../View/Components/ContactUs/index';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/global" element={<GlobalManinPage/>} />
      <Route path="/global/servises" element={<GlobalServices/>} />
      <Route path="/global/about" element={<GlobalAbout/>} />
      <Route path="/global/blogs" element={<GlobalBlogs/>} />
      <Route path="/global/contact-us" element={<GlobalConatctUs/>} />
    </Routes>
  );
}

export default AppRoutes;

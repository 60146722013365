import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CardMedia, Stack } from '@mui/material';

import Founder1 from '../../../assets/Landing/fu1.png'; // Replace with actual image paths
import Founder2 from '../../../assets/Landing/fu1.png'; // Replace with actual image paths

export default function FoundersCard() {
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#f1f1f1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
        boxShadow: 'none', // Remove box-shadow from main card
      }}
    >
      <Stack
        direction="row"
        spacing={4}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Left Founder Card */}
        <Card
          sx={{
            width: '40%',
            height: '80vh',
            background: '#f1f1f1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '20px',
            padding: 3,
            boxShadow: 'none', // Remove box-shadow
          }}
        >
          <CardMedia
            component="img"
            src={Founder1}
            alt="Founder 1"
            sx={{ width: '54%', borderRadius: '20px' }}
          />
          <Typography variant="h5" sx={{ color: '#252525', marginTop: 2 }}>
            Puraveen Yogarasa
          </Typography>
          <Typography sx={{ color: '#252525', textAlign: 'center' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            pharetra eu massa eu tempor.
          </Typography>
        </Card>

        {/* Vertical FOUNDERS Text */}
        <Stack justifyContent="center" alignItems="center" sx={{ height: '70vh' }}>
          <Typography
            variant="h3"
            sx={{
                color: 'transparent', // Set the fill color to transparent
                fontWeight: 'bold',
                writingMode: 'vertical-rl',
                textOrientation: 'upright',
                transform: 'rotate(360deg)',
                WebkitTextStroke: '2px #1C486A', // Add outline with white color
                textStroke: '1px #1C486A', // Fallback for browsers
            }}
          >
            FOUNDERS
          </Typography>
        </Stack>

        {/* Right Founder Card (Placeholder) */}
        <Card
          sx={{
            width: '40%',
            height: '80vh',
            background: '#f1f1f1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '20px',
            padding: 3,
            boxShadow: 'none', // Remove box-shadow
          }}
        >
          <CardMedia
            component="img"
            src={Founder2}
            alt="Founder 2"
            sx={{ width: '54%', borderRadius: '20px' }}
          />
          <Typography variant="h5" sx={{ color: '#252525', marginTop: 2 }}>
            Lalidaran Ramasamy
          </Typography>
          <Typography sx={{ color: '#252525', textAlign: 'center' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            pharetra eu massa eu tempor.
          </Typography>
        </Card>
      </Stack>
    </Card>
  );
}

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Router } from 'react-router-dom';

// Routes
import Routes from './routes/index';

function App() {
  return (
    <Router>
      <Box>
        <Stack direction="column" spacing={0}>
          <Routes />
        </Stack>
      </Box>
    </Router>
  );
}

export default App;

import React from 'react';
import { Typography, TextField, Button, Box, Stack, useMediaQuery, useTheme } from '@mui/material';

// Import your images
import Founder1 from '../../../assets/Landing/contactus.png'; 
import Founder2 from '../../../assets/Landing/fu1.png'; 

export default function FoundersCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        width: '100%',
        height: isMobile ? 'auto' : '120vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
      }}
    >
      {/* Main structure (form + typography) */}
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 2 : 2}
        sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {/* Left: Contact Form */}
        <Box
          sx={{
            width: isMobile ? '100%' : '40%',
            height: isMobile ? 'auto' : '80vh',
            background: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '20px',
            padding: 3,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* Form inside the box */}
          <Box
            component="form"
            sx={{
              width: '100%',
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* Input fields */}
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              sx={{ marginBottom: 2 }}
            />

            {/* Submit button */}
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#1C486A',
                color: '#1C486A',
                marginTop: 2,
                '&:hover': {
                  backgroundColor: '#1C486A',
                  color: '#fff',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>

        {/* Right: Typography (Write to Us) */}
        <Box
          sx={{
            width: isMobile ? '100%' : '50%', // Take up 50% on larger screens
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: isMobile ? 'auto' : '80vh', // Set height to match the form
          }}
        >
          <Stack direction={'column'}>
          <Typography
            variant='h4'
            color='#1C486A'
            fontWeight={700}
            textAlign={'center'} // Center the text horizontally
          >
            Write to Us
          </Typography>
          <Typography variant='subtitle1' textAlign={'center'}>
          Fill the form and send us an email <br/>
          We promise to get back to you as soon as possible
          </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

import React from 'react';
import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';

const FooterBottom = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ backgroundColor: '#333', color: '#fff', padding: isMobile ? '5px 0' : '10px 0' }}>
      <Grid
        container
        justifyContent={isMobile ? 'center' : 'space-between'}
        alignItems="center"
        sx={{ paddingX: 2 }}
      >
        {/* Left: Copyright Text */}
        <Grid item xs={12} md={6} textAlign={isMobile ? 'center' : 'left'}>
          <Typography variant="body2">
            Copyright © 2024 | Kaleido International | All rights reserved
          </Typography>
        </Grid>

        {/* Right: Crafted By Text */}
        <Grid item xs={12} md={6} textAlign={isMobile ? 'center' : 'right'}>
          <Typography variant="body2">
            Crafted by Arrow Reach Digital Technologies
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterBottom;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CardMedia, Grid, Button, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

// Dummy imports for icons (replace with your own images or icons)
import IMG1 from '../../../assets/Landing/img1.png';
import IMG2 from '../../../assets/Landing/img2.png';
import IMG3 from '../../../assets/Landing/img3.png';
import IMG4 from '../../../assets/Landing/img4.png';
import IMG5 from '../../../assets/Landing/img5.png';
import IMG6 from '../../../assets/Landing/img6.png';

export default function IndustriesCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('340','sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', '740'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('340'));

  return (
    <Box
      sx={{
        height: 'auto',
        backgroundColor: '#f1f1f1',
        position: 'relative',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '50px',
        [theme.breakpoints.up('md')]: {
          paddingLeft: '100px',
          paddingRight: '100px',
        }
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: '#001f3f',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 5,
          textTransform: 'uppercase',
          fontSize: isMobile ? '35px' : isExtraSmall ? '27px' : '50px',
        }}
      >
        Our{' '}
        <span style={{ WebkitTextStroke: '1px #001f3f', color: 'transparent' }}>
          Services
        </span>
      </Typography>

      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        {/* First Vertical Line of Images */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG1}
              alt="Industry 1"
            />
            <Typography sx={{ color: 'black', marginTop: 1, fontWeight: '700' }}>
              Book Keeping
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG2}
              alt="Industry 2"
            />
            <Typography sx={{ color: 'black', marginTop: 1, fontWeight: '700' }}>
              Tax Preparation
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG3}
              alt="Industry 3"
            />
            <Typography sx={{ color: 'black', marginTop: 1, fontWeight: '700' }}>
              Advisory Services
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG4}
              alt="Industry 4"
            />
            <Typography sx={{ color: 'black', marginTop: 1, fontWeight: '700' }}>
              Forecasting
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG5}
              alt="Industry 5"
            />
            <Typography sx={{ color: 'black', marginTop: 1, fontWeight: '700' }}>
              Management Accounting
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG6}
              alt="Industry 6"
            />
            <Typography sx={{ color: 'black', marginTop: 1, fontWeight: '700' }}>
              Budgeting
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Bottom-centered Contact Button */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 4,
        }}
      >
        <Button
          component={Link}
          to="/global/contact-us"
          sx={{
            width: '150px',
            color: 'white',
            backgroundColor: '#001f3f',
            borderRadius: '20px',
            padding: '2px',
            fontSize: '20px',
            fontWeight: '700',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#434343',
            },
          }}
        >
          See more
        </Button>
      </Box>
    </Box>
  );
}

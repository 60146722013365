import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CardMedia, Stack, useMediaQuery, useTheme } from '@mui/material';

import Founder1 from '../../../assets/Landing/fu1.png'; // Replace with actual image paths
import Founder2 from '../../../assets/Landing/fu1.png'; // Replace with actual image paths

export default function FoundersCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define mobile breakpoint

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#f1f1f1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
        boxShadow: 'none', // Remove box-shadow from main card
      }}
    >
      <Stack
        direction={isMobile ? 'row' : 'row'} // Keep row direction, stack on right side
        spacing={isMobile ? 2 : 4}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Vertical FOUNDERS Text */}
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: isMobile ? 'auto' : '70vh', // Adjust height for mobile
            width: isMobile ? '20%' : 'auto',   // Adjust width for mobile
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: 'transparent', // Set the fill color to transparent
              fontWeight: 'bold',
              writingMode: 'vertical-rl', // Keep text vertical on mobile
              textOrientation: 'upright',
              WebkitTextStroke: '2px #1C486A', // Add outline
              textStroke: '1px #1C486A', // Fallback for browsers
              transform: 'rotate(360deg)', // Keep rotated
            }}
          >
            FOUNDERS
          </Typography>
        </Stack>

        {/* Stack Cards on Right Side for Mobile */}
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 2 : 4}
          sx={{
            width: isMobile ? '75%' : 'auto', // Adjust width for mobile
            alignItems: 'center',
          }}
        >
          {/* Left Founder Card */}
          <Card
            sx={{
              width: isMobile ? '100%' : '40%', // Adjust width for mobile
              height: isMobile ? 'auto' : '80vh', // Adjust height for mobile
              background: '#f1f1f1',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              borderRadius: '20px',
              padding: 3,
              boxShadow: 'none', // Remove box-shadow
            }}
          >
            <CardMedia
              component="img"
              src={Founder1}
              alt="Founder 1"
              sx={{ width: '54%', borderRadius: '20px' }}
            />
            <Typography variant="h5" sx={{ color: '#252525', marginTop: 2, textAlign:'center' }}>
              Puraveen Yogarasa
            </Typography>
            <Typography sx={{ color: '#252525', textAlign: 'center' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              pharetra eu massa eu tempor.
            </Typography>
          </Card>

          {/* Right Founder Card */}
          <Card
            sx={{
              width: isMobile ? '100%' : '40%', // Adjust width for mobile
              height: isMobile ? 'auto' : '80vh', // Adjust height for mobile
              background: '#f1f1f1',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              borderRadius: '20px',
              padding: 3,
              boxShadow: 'none', // Remove box-shadow
            }}
          >
            <CardMedia
              component="img"
              src={Founder2}
              alt="Founder 2"
              sx={{ width: '54%', borderRadius: '20px' }}
            />
            <Typography variant="h5" sx={{ color: '#252525', marginTop: 2 , textAlign:'center'}}>
              Lalidaran Ramasamy
            </Typography>
            <Typography sx={{ color: '#252525', textAlign: 'center' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              pharetra eu massa eu tempor.
            </Typography>
          </Card>
        </Stack>
      </Stack>
    </Card>
  );
}

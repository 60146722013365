import React from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useNavigate } from 'react-router-dom';
import logoImage from '../../../assets/Logo/Logo.png';
import img1 from '../../../assets/Landing/right.png';
import img2 from '../../../assets/Landing/left.png';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleSriLankaClick = () => {
    navigate('/Sri_Lanka');
  };

  const handleGlobalClick = () => {
    navigate('/Global');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100vh"
      padding={2}
      sx={{ position: 'relative' }}
    >
      {/* Top Left Image */}
      <Box
        component="img"
        src={img1}
        alt="Top Left"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: { xs: '50px', sm: '75px', md: '100px' },
          height: 'auto',
          margin: 2,
        }}
      />

      {/* Top Typography */}
      <Typography 
        variant="h2" 
        color="primary" 
        align="center" 
        marginTop={8}
        sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}
      >
        Welcome to...!
      </Typography>

      {/* Center Logo */}
      <Box
        component="img"
        src={logoImage}
        alt="Logo"
        sx={{
          width: { xs: '120px', sm: '180px', md: '250px' },
          height: 'auto',
          marginTop: 4,
          marginBottom: 4,
        }}
      />

      {/* Bottom Arrows with Region Selection Text */}
      <Box>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={4} sm={4} container direction="column" alignItems="center">
            <IconButton
              color="primary"
              aria-label="Sri Lanka"
              size="large"
              onClick={handleSriLankaClick}
              sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                borderRadius: '50%',
                padding: 2,
                width: { xs: 50, sm: 60 },
                height: { xs: 50, sm: 60 },
              }}
            >
              <ArrowBackIosRoundedIcon fontSize="inherit" />
            </IconButton>
            <Typography 
              variant="body1" 
              color="primary" 
              align="center"
              sx={{ fontSize: { xs: '0.75rem', sm: '1rem' } }}
            >
              Sri Lanka
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} container direction="column" alignItems="center">
            <Typography 
              variant="h5" 
              color="primary" 
              align="center" 
              sx={{ mb: 1, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
            >
              Choose your region
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} container direction="column" alignItems="center">
            <IconButton
              color="primary"
              aria-label="Global"
              size="large"
              onClick={handleGlobalClick}
              sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                borderRadius: '50%',
                padding: 2,
                width: { xs: 50, sm: 60 },
                height: { xs: 50, sm: 60 },
              }}
            >
              <ArrowForwardIosRoundedIcon fontSize="inherit" />
            </IconButton>
            <Typography 
              variant="body1" 
              color="primary" 
              align="center"
              sx={{ fontSize: { xs: '0.75rem', sm: '1rem' } }}
            >
              Global
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Bottom Right Image */}
      <Box
        component="img"
        src={img2}
        alt="Bottom Right"
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: { xs: '50px', sm: '75px', md: '100px' },
          height: 'auto',
          margin: 2,
        }}
      />
    </Box>
  );
};

export default LandingPage;

import React from 'react';
import { Card, Typography, Stack, useMediaQuery, useTheme, Grid } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import IMG1 from '../../../assets/Landing/tech.png';
import IMG2 from '../../../assets/Landing/cloths.png';
import IMG3 from '../../../assets/Landing/education.png';
import IMG4 from '../../../assets/Landing/food.png';
import IMG5 from '../../../assets/Landing/bew.png';
import IMG6 from '../../../assets/Landing/health.png';

export default function IndustriesCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const industries = [
    { img: IMG1, title: 'Technology' },
    { img: IMG2, title: 'Clothing' },
    { img: IMG3, title: 'Education' },
    { img: IMG4, title: 'Food' },
    { img: IMG5, title: 'Beverage' },
    { img: IMG6, title: 'Healthcare' },
  ];

  return (
    <Card
      sx={{
        height: 'auto',
        backgroundColor: '#1C486A',
        padding: 4,
      }}
    >
      <Stack justifyContent="center" alignItems="center" spacing={4}>
        {/* Grid Layout for all screens */}
        <Grid container spacing={4} justifyContent="center">
          {industries.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#1C486A',
                  border: 'solid #ffffff',
                  borderRadius: '20px',
                  marginLeft:'-20px'
                }}
              >
                <Stack direction="column" justifyContent="center" alignItems="center">
                  <CardMedia component="img" src={item.img} alt={item.title} sx={{ width: '60px' }} />
                  <Typography sx={{ color: '#fff', marginTop: 1 }}>{item.title}</Typography>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* "Industries" Text */}
        <Typography
          variant="h3"
          sx={{
            color: 'transparent',
            fontWeight: 'bold',
            WebkitTextStroke: '1px #fff',
            textAlign: 'center',
            fontSize: {xs:'30px',sm:'50px',md:'50px'},

          }}
        >
          INDUSTRIES
        </Typography>

        {/* Right Section */}
        <Stack
          justifyContent="center"
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Typography variant="h4" sx={{ color: '#fff', fontWeight: 'bold', fontSize: {xs:'30px',sm:'50px',md:'50px'}, marginBottom: 2 , marginTop:{xs:'-30px',sm:'-25px'}}}>
            WE SERVE
          </Typography>
          <Typography sx={{ color: '#fff', lineHeight: 1.8 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra venenatis auctor. Donec ornare nisl
            quis laoreet gravida. Donec sollicitudin massa at orci lacinia consequat.
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box, Stack, Link, useMediaQuery } from '@mui/material';
import img1 from '../../../assets/Landing/fb.png'; // Ensure path is correct
import img2 from '../../../assets/Landing/insta.png';
import img3 from '../../../assets/Landing/linkdin.png';

const MainBanner = () => {
  // Detect if the screen is mobile (width < 600px)
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Grid container spacing={2}>
      {/* Title */}
      <Grid item xs={12}>
        <Typography
          variant={isMobile ? 'h4' : 'h3'} // Smaller font for mobile
          textAlign='center'
          fontWeight={700}
          color='#1C486A'
        >
          Let’s Connect
        </Typography>
        <Typography
          variant={isMobile ? 'h6' : 'h5'} // Smaller font for mobile
          textAlign='center'
          fontWeight={300}
          color='#1C486A'
        >
          Click on the links below to <br />
          follow or to get in touch
        </Typography>
      </Grid>

      {/* Facebook link */}
      <Grid 
        item 
        xs={12} 
        sm={4} 
        container
        direction={isMobile ? 'column' : 'row'} // Stack vertically on mobile, row on desktop
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="column" spacing={2} alignItems="center">
          <a href="https://www.facebook.com/kaledointernational" target="_blank" rel="noopener noreferrer">
            <img 
              src={img1} 
              alt="Facebook" 
              style={{
                maxWidth: isMobile ? '50%' : '100%', // Shrink image on mobile
                height: 'auto'
              }} 
            />
          </a>
          <Link textAlign='center'>@KaleidoInternational</Link>
        </Stack>
      </Grid>

      {/* Instagram link */}
      <Grid 
        item 
        xs={12} 
        sm={4} 
        container
        direction={isMobile ? 'column' : 'row'} // Stack vertically on mobile, row on desktop
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="column" spacing={2} alignItems="center">
          <a href="https://www.instagram.com/kaleido_international/" target="_blank" rel="noopener noreferrer">
            <img 
              src={img2} 
              alt="Instagram" 
              style={{
                maxWidth: isMobile ? '50%' : '100%', 
                height: 'auto'
              }} 
            />
          </a>
          <Link textAlign='center'>@kaleido_International</Link>
        </Stack>
      </Grid>

      {/* Twitter/X link */}
      <Grid 
        item 
        xs={12} 
        sm={4} 
        container
        direction={isMobile ? 'column' : 'row'} // Stack vertically on mobile, row on desktop
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="column" spacing={2} alignItems="center">
          <a href="https://x.com/kaleido_international/" target="_blank" rel="noopener noreferrer">
            <img 
              src={img3} 
              alt="Twitter/X" 
              style={{
                maxWidth: isMobile ? '50%' : '100%', 
                height: 'auto'
              }} 
            />
          </a>
          <Link textAlign='center'>Kaleido International</Link>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MainBanner;

import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Typography, useMediaQuery, useTheme, } from '@mui/material';

// Dummy imports for icons (replace with your own images or icons)
import IMG1 from '../../../assets/Landing/client1.png';
import IMG2 from '../../../assets/Landing/client2.png';
import IMG3 from '../../../assets/Landing/client3.png';
import IMG4 from '../../../assets/Landing/client1.png';
import IMG5 from '../../../assets/Landing/client2.png';

const MyCarousel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('340','sm'));
  const isCustomTablet = useMediaQuery(theme.breakpoints.between('900', '967'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', '740'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('340'));

  return (
    <Box
      sx={{
        height: 'auto',
        backgroundColor: '#f1f1f1',
        position: 'relative',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '50px',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: '#001f3f',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: { xs: 3, sm: 5, md: 6, lg: 6 },
          textTransform: 'uppercase',
          fontSize: isMobile ? '35px' : isExtraSmall?'27px':'50px',
        }}
      >
        Voice of our{' '}
        <span style={{ WebkitTextStroke: '1px #001f3f', color: 'transparent' }}>
        Clients
        </span>
      </Typography>
      <Box
        sx={{
          width: { xs: '270px', sm: '500px', md: '900px', lg: '1000px' },
          '@media (min-width: 900px) and (max-width: 967px)': {
              width: '850px', // custom viewport between 600px and 700px
          },
          '@media (min-width: 550px) and (max-width: 600px)': {
              width: '500px', // custom viewport between 600px and 700px
          },
          '@media (min-width: 500px) and (max-width: 550px)': {
              width: '450px', // custom viewport between 600px and 700px
          },
          height: 'auto',
          backgroundColor: '#f1f1f1',
          position: 'relative',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Carousel
  additionalTransfrom={0}
  autoPlay={true}
  autoPlaySpeed={2000}
  centerMode={false}
  className=""
  containerClass="container-with-dots"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  keyBoardControl
  minimumTouchDrag={80}
  pauseOnHover
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  arrows={false} 
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 900
      },
      items: 4, // Show 4 items in desktop version
      partialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: {
        max: 500,
        min: 0
      },
      items: 1,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: {
        max: 900,
        min: 500
      },
      items: 2,
      partialVisibilityGutter: 30
    }
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  showDots={false}
  sliderClass=""
  slidesToSlide={1}
  swipeable
>

          <div style={{ padding: '0 15px' }}>
            <img src={IMG1} alt="IMG1" className="carousel-image" style={{ width: '200px', height: '200px', borderRadius:'100%' }} />
            <Typography variant="h5" textAlign={'center'}>
              Director Name
            </Typography>
            <Typography variant="subtitle1" textAlign={'center'}>
              Designation in the Company
            </Typography>
            <Typography variant="subtitle2" textAlign={'center'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra venenatis auctor. Donec ornare nisl quis laoreet gravida. Donec sollicitudin massa at orci lacinia consequat. 
            </Typography>
          </div>
          <div style={{ padding: '0 15px' }}>
            <img src={IMG2} alt="IMG2" className="carousel-image" style={{ width: '200px', height: '200px', borderRadius:'100%' }} />
            <Typography variant="h5" textAlign={'center'}>
              Director Name
            </Typography>
            <Typography variant="subtitle1" textAlign={'center'}>
              Designation in the Company
            </Typography>
            <Typography variant="subtitle2" textAlign={'center'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra venenatis auctor. Donec ornare nisl quis laoreet gravida. Donec sollicitudin massa at orci lacinia consequat. 
            </Typography>
          </div>
          <div style={{ padding: '0 15px' }}>
            <img src={IMG3} alt="IMG3" className="carousel-image" style={{ width: '200px', height: '200px', borderRadius:'100%' }} />
            <Typography variant="h5" textAlign={'center'}>
              Director Name
            </Typography>
            <Typography variant="subtitle1" textAlign={'center'}>
              Designation in the Company
            </Typography>
            <Typography variant="subtitle2" textAlign={'center'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra venenatis auctor. Donec ornare nisl quis laoreet gravida. Donec sollicitudin massa at orci lacinia consequat. 
            </Typography>
          </div>
          <div style={{ padding: '0 15px' }}>
            <img src={IMG4} alt="IMG4" className="carousel-image" style={{ width: '200px', height: '200px', borderRadius:'100%' }} />
            <Typography variant="h5" textAlign={'center'}>
              Director Name
            </Typography>
            <Typography variant="subtitle1" textAlign={'center'}>
              Designation in the Company
            </Typography>
            <Typography variant="subtitle2" textAlign={'center'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra venenatis auctor. Donec ornare nisl quis laoreet gravida. Donec sollicitudin massa at orci lacinia consequat. 
            </Typography>
          </div>
          <div style={{ padding: '0 15px' }}>
            <img src={IMG5} alt="IMG5" className="carousel-image" style={{ width: '200px', height: '200px', borderRadius:'100%' }} />
            <Typography variant="h5" textAlign={'center'}>
              Director Name
            </Typography>
            <Typography variant="subtitle1" textAlign={'center'}>
              Designation in the Company
            </Typography>
            <Typography variant="subtitle2" textAlign={'center'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra venenatis auctor. Donec ornare nisl quis laoreet gravida. Donec sollicitudin massa at orci lacinia consequat. 
            </Typography>
          </div>
        </Carousel>
      </Box>
    </Box>
  );
};

export default MyCarousel;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardMedia, CardContent, Grid, Button, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import IMG1 from '../../../assets/Landing/img1.png';
import IMG2 from '../../../assets/Landing/img2.png';
import IMG3 from '../../../assets/Landing/img3.png';
import IMG4 from '../../../assets/Landing/img4.png';
import IMG5 from '../../../assets/Landing/img5.png';

export default function IndustriesCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('340', 'sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('340'));

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: '#f1f1f1',
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          paddingLeft: '100px',
          paddingRight: '100px',
        }
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: '#001f3f',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 5,
          textTransform: 'uppercase',
          fontSize: isMobile ? '35px' : isExtraSmall ? '27px' : '50px',
        }}
      >
        Our{' '}
        <span style={{ WebkitTextStroke: '1px #001f3f', color: 'transparent' }}>
          Services
        </span>
      </Typography>

      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {/* First Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG1}
              alt="Book Keeping"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Stay ahead of your financial management with our comprehensive online bookkeeping services.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Book Keeping
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Second Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG2}
              alt="Tax Preparation"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Navigate the complexities of taxation with our expert tax preparation solutions.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Tax Preparation
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Third Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG3}
              alt="Advisory Services"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Receive expert advisory services for your business decisions.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Advisory Services
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Fourth Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG4}
              alt="Forecasting"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Stay ahead of market trends with our detailed forecasting services.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Forecasting
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Fifth Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG5}
              alt="Management Accounting"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Get insightful management accounting solutions tailored to your needs.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Management Accounting
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG1}
              alt="Book Keeping"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Stay ahead of your financial management with our comprehensive online bookkeeping services.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Book Keeping
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Second Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG2}
              alt="Tax Preparation"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Navigate the complexities of taxation with our expert tax preparation solutions.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Tax Preparation
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Third Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG3}
              alt="Advisory Services"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Receive expert advisory services for your business decisions.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Advisory Services
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Fourth Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG4}
              alt="Forecasting"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Stay ahead of market trends with our detailed forecasting services.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Forecasting
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Fifth Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG5}
              alt="Management Accounting"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Get insightful management accounting solutions tailored to your needs.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Management Accounting
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG1}
              alt="Book Keeping"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Stay ahead of your financial management with our comprehensive online bookkeeping services.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Book Keeping
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Second Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG2}
              alt="Tax Preparation"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Navigate the complexities of taxation with our expert tax preparation solutions.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Tax Preparation
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Third Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG3}
              alt="Advisory Services"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Receive expert advisory services for your business decisions.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Advisory Services
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Fourth Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG4}
              alt="Forecasting"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Stay ahead of market trends with our detailed forecasting services.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Forecasting
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Fifth Service */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardMedia
              sx={cardMediaStyles}
              component="img"
              src={IMG5}
              alt="Management Accounting"
            />
            <Box sx={boxStyles}>
              <CardContent>
                <Typography variant="body1">
                  Get insightful management accounting solutions tailored to your needs.
                </Typography>
              </CardContent>
              <Typography variant="h6" sx={typographyStyles}>
                Management Accounting
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

// Styles for the cards, media, box, and typography
const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: '#1C486A',
  color:'#f1f1f1',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  '&:hover': { transform: 'scale(1.05)', boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)' }
};

const cardMediaStyles = {
  height: { xs: '200px', sm: '250px', md: '250px' },
  width: { xs: '100%', sm: '100%', md: '100%' },
  borderRadius: '12px 12px 0 0'
};

const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
  padding: '16px',
  textAlign: 'center'
};

const typographyStyles = {
  marginTop: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#f1f1f1'
};

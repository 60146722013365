import { Stack, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

// Importing logo images
import IMG1 from '../../../assets/Landing/logo1.png';
import IMG2 from '../../../assets/Landing/logo3.png';
import IMG3 from '../../../assets/Landing/logo4.png';
import IMG4 from '../../../assets/Landing/logo5.png';
import IMG5 from '../../../assets/Landing/logo7.png';
import IMG6 from '../../../assets/Landing/logo8.png';

const End = () => {
  // Using theme and media query to make the component responsive
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile screen size detection

  return (
    <Stack sx={{backgroundColor:'#f1f1f1',paddingBottom:5, paddingTop:7}} direction="column" spacing={isMobile ? 2 : 4} alignItems="center" height={isMobile ? 'auto' : '100%'} padding={isMobile ? 2 : 0}>
      {/* Title */}
      <Typography variant={isMobile ? 'h5' : 'h3'} fontWeight="bold" textAlign="center" sx={{textTransform:'uppercase'}} >
        Our Partners
      </Typography>
      
      {/* Logos Row */}
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 4 : 12} // Adjust spacing for mobile
        alignItems="center"
        justifyContent="center"
      >
        {/* Logos with fixed size, no border-radius */}
        <Box component="img" src={IMG1} alt="Logo 1" sx={{ width: {sm:70,md:90,lg:120}, height: 'auto' }} />
        <Box component="img" src={IMG2} alt="Logo 2" sx={{ width: {sm:70,md:90,lg:120}, height: 'auto' }} />
        <Box component="img" src={IMG3} alt="Logo 3" sx={{ width: {sm:70,md:90,lg:120}, height: 'auto' }} />
        <Box component="img" src={IMG4} alt="Logo 4" sx={{ width: {sm:70,md:90,lg:120}, height: 'auto' }} />
        <Box component="img" src={IMG5} alt="Logo 5" sx={{ width: {sm:70,md:90,lg:120}, height: 'auto' }} />
      </Stack>
    </Stack>
  );
};

export default End;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CardMedia, Grid, Button, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from './Slider';

// Dummy imports for icons (replace with your own images or icons)
import IMG1 from '../../../assets/Landing/about1.png';
import IMG2 from '../../../assets/Logo/Logo.png';
import IMG3 from '../../../assets/Landing/about2.png';
import IMG4 from '../../../assets/Landing/fu1.png';
import IMG5 from '../../../assets/Landing/img5.png';
import IMG6 from '../../../assets/Landing/img6.png';

import FAQ from './FAQ';

export default function IndustriesCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('340','sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', '740'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('340'));

  return (
    <Box
      sx={{
        height: 'auto',
        backgroundColor: '#f1f1f1',
        position: 'relative',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '50px',
        [theme.breakpoints.up('md')]: {
          paddingLeft: '100px',
          paddingRight: '100px',
        }
      }}
    >
      <Grid
        container
        spacing={8}
        justifyContent="center"
        alignItems="center"
      >
        {/* First Vertical Line of Images */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
<Typography variant="subtitle1" sx={{ marginTop: 1, textAlign: 'justify' }}>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquam turpis ornare fringilla convallis. Sed ac justo at eros pellentesque laoreet. Proin mattis cursus posuere. Etiam a nisl a arcu vestibulum imperdiet non vel erat. Cras interdum tempus massa et commodo. Suspendisse aliquam urna a arcu ultricies, quis sagittis velit suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquam turpis ornare fringilla convallis. Sed ac justo at eros pellentesque laoreet. Proin mattis cursus posuere. Etiam a nisl a arcu vestibulum imperdiet non vel erat. Cras interdum tempus massa et commodo.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG2}
              alt="Industry 2"
            />
          </Box>
        </Grid>
      </Grid>
      {/* 2nd row */}
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        {/* First Vertical Line of Images */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              mt:{xs:2,sm:5,md:10,lg:10}
            }}
          >
            <Typography
        variant="h3"
        sx={{
          color: '#001f3f',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 0,
          textTransform: 'uppercase',
          fontSize: isMobile ? '35px' : isExtraSmall ? '27px' : '40px',
        }}
      >
        Our Mission
        
      </Typography>

<Typography variant="subtitle1" sx={{ marginTop: 1, textAlign: 'justify' }}>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquam turpis ornare fringilla convallis. Sed ac justo at eros pellentesque laoreet. Proin mattis cursus posuere. Etiam a nisl a arcu vestibulum imperdiet non vel erat. Cras interdum tempus massa et commodo. Suspendisse aliquam urna a arcu ultricies, quis sagittis velit suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquam turpis ornare fringilla convallis. Sed ac justo at eros pellentesque laoreet. Proin mattis cursus posuere. Etiam a nisl a arcu vestibulum imperdiet non vel erat. Cras interdum tempus massa et commodo.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG1}
              alt="Industry 2"
            />
          </Box>
        </Grid>
      </Grid>
      {/* 3rd row */}
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '200px', sm: '250px', md: '250px' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG3}
              alt="Industry 2"
            />
          </Box>
        </Grid>
        {/* First Vertical Line of Images */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              mt:{xs:2,sm:5,md:10,lg:10}

            }}
          >
            <Typography
        variant="h3"
        sx={{
          color: '#001f3f',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 0,
          textTransform: 'uppercase',
          fontSize: isMobile ? '35px' : isExtraSmall ? '27px' : '40px',
        }}
      >
        Our Vision
        
      </Typography>
      <Typography variant="subtitle1" sx={{ marginTop: 1, textAlign: 'justify' }}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquam turpis ornare fringilla convallis. Sed ac justo at eros pellentesque laoreet. Proin mattis cursus posuere. Etiam a nisl a arcu vestibulum imperdiet non vel erat. Cras interdum tempus massa et commodo. Suspendisse aliquam urna a arcu ultricies, quis sagittis velit suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquam turpis ornare fringilla convallis. Sed ac justo at eros pellentesque laoreet. Proin mattis cursus posuere. Etiam a nisl a arcu vestibulum imperdiet non vel erat. Cras interdum tempus massa et commodo.
            </Typography>
          </Box>
        </Grid> 
      </Grid>
      {/* 4th row */}
      {/* <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <Slider/>
      </Grid> */}
      {/* 5th row */}
      <Grid
        container
        spacing={8}
        justifyContent="center"
        alignItems="center"
      >
        {/* First Vertical Line of Images */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              mt:{xs:2,sm:5,md:10,lg:10}

            }}
          >
            <Typography
        variant="h3"
        sx={{
          color: '#001f3f',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 0,
          textTransform: 'uppercase',
          fontSize: isMobile ? '35px' : isExtraSmall ? '27px' : '40px',
        }}
      >
            Puraveen Yogarasa
        
      </Typography>
            
            <Typography variant="subtitle1" sx={{ marginTop: 1, textAlign: 'justify' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pharetra eu massa eu tempor. Vestibulum lacinia iaculis nunc, id malesuada velit porta vitae. Quisque ut nisi quis nisl facilisis dapibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pharetra eu massa eu tempor. Vestibulum lacinia iaculis nunc, id malesuada velit porta vitae. Quisque ut nisi quis nisl facilisis dapibus. 
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              mt:{xs:2,sm:5,md:10,lg:10}

            }}
          >
            <CardMedia
              sx={{
                height: { xs: '100%', sm: '100%', md: '100%' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG4}
              alt="Industry 2"
            />
          </Box>
        </Grid>
      </Grid>
      {/* 6th row */}
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: '100%', sm: '100%', md: '100%' },
                width: { xs: '200px', sm: '250px', md: '90%' },
                borderRadius: '15px',
              }}
              component="img"
              src={IMG4}
              alt="Industry 2"
            />
          </Box>
        </Grid>
        {/* First Vertical Line of Images */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography variant='h3' sx={{ marginTop: 1 }}>
            Lalidaran Ramasamy
            </Typography>
            <Typography variant='subtitle1' sx={{ marginTop: 1 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pharetra eu massa eu tempor. Vestibulum lacinia iaculis nunc, id malesuada velit porta vitae. Quisque ut nisi quis nisl facilisis dapibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pharetra eu massa eu tempor. Vestibulum lacinia iaculis nunc, id malesuada velit porta vitae. Quisque ut nisi quis nisl facilisis dapibus. 
            </Typography>
          </Box>
        </Grid> 
      </Grid>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{
        
          mt:{xs:2,sm:5,md:10,lg:10}

        }}
      >
        <FAQ/>
      </Grid>
    </Box>
  );
}

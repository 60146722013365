import { Stack, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useMediaQuery,useTheme } from '@mui/material';
import Nav_bar from '../../Nav_Bar/index.jsx'
import Banner1 from './MainBanner';
import Banner2 from './ContactUs.jsx';
import Footer1 from './Footer.jsx';
import Footer2 from './Footer2.jsx';

function App() {
  // Use Material-UI's useMediaQuery hook to determine screen size
  //const isMobile = useMediaQuery('(max-width:200px)'); // true if screen width is 600px or less
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('845'));

  return (
    <Box sx={{backgroundColor:'#000000'}}>
      <Grid container spacing={0} direction="column">
      <Grid item xs={12} >
          <Nav_bar />
        </Grid>
        <Grid item xs={12} sx={{mt:8}}>
          <Banner1 />
        </Grid>
        <Grid item xs={12}>
          <Banner2 />
        </Grid>
        <Grid item xs={12}>
          <Footer1 />
        </Grid>
        <Grid item xs={12}>
          <Footer2 />
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
